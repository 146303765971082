import * as Sentry from '@sentry/nextjs';
import type { GetServerSidePropsContext, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { TextBlockSkeleton } from '@hotelplan/components.common.text-block';
import { PageType } from '@hotelplan/graphql.types';
import { mapGlobalSearchStateToFormState } from '@hotelplan/libs.gss';
import { gssUnitRef } from '@hotelplan/libs.gss-api';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { ServerSideUnit } from '@hotelplan/libs.ssp-units';
import { trackSearch } from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Container from 'components/domain/container/Container';
import { DynamicComponents } from 'components/domain/dynamic-components';
import Error from 'components/domain/error-page-content/Error';
import HomeSearchForm from 'components/domain/home/HomeSearchForm';
import { mapSearchControlFormStateToTrackableData } from 'components/domain/searchControl/SearchControl.mappers';
import StyledTextBlock from 'components/domain/StyledTextBlock';
import { createPageServerFn } from 'config/pageUnitsConfig';
import {
  Get404MetaDataDocument,
  Get404MetaDataQuery,
  Get404MetaDataQueryVariables,
} from 'graphql/404/Get404MetaData.generated';
import {
  Get404StaticComponentsDocument,
  Get404StaticComponentsQuery,
  Get404StaticComponentsQueryVariables,
  useGet404StaticComponentsQuery,
} from 'graphql/404/Get404StaticComponents.generated';
import {
  GetHomeSearchControlValuesDocument,
  GetHomeSearchControlValuesQuery,
  GetHomeSearchControlValuesQueryVariables,
} from 'graphql/home/GetHomeSearchControlValues.generated';

interface IErrorPageProps {
  err?: any;
  statusCode?: number;
  getServerSideProps?: boolean;
}

const ErrorTextBlockSkeleton = styled(TextBlockSkeleton)(
  ({ theme }) => theme.text.textBlock
);

const ErrorTextBlock = styled(StyledTextBlock)(
  sx2CssThemeFn({
    mt: [5, '70px'],
    mb: ['65px', '80px'],
    px: [4, 0],
  })
);

const ErrorPageSearchForm = styled(HomeSearchForm)(
  sx2CssThemeFn({
    position: [null, 'relative'],
    top: [null, '0px'],
  })
);

const SearchControlWrapper = styled.div(
  sx2CssThemeFn({
    backgroundImage: [
      null,
      'linear-gradient(180deg,#F5F4F4 0%,#F5F4F4 120px,#FFFFFF 120px,#FFFFFF 100%);',
    ],
    pt: [null, '45px'],
    position: [null, 'relative'],
    minHeight: [null, '255px'],

    '.searchControlFormLayoutWrapper': {
      mx: [null, 'auto'],
      border: [null, '1px solid'],
      borderColor: [null, 'borderGreyFifth'],
      borderRadius: [null, 'roundedTop'],
      boxShadow: [null, 'cardShadow'],
    },
  })
);

const NotFoundPage: React.FC = () => {
  const { data, loading } = useGet404StaticComponentsQuery();

  return (
    <>
      <SearchControlWrapper>
        <ErrorPageSearchForm
          postSubmit={state =>
            trackSearch({
              searchControl: mapSearchControlFormStateToTrackableData(state),
            })
          }
        />
      </SearchControlWrapper>
      <Container>
        {loading ? (
          <ErrorTextBlockSkeleton uniqueKey={'404Text'} />
        ) : (
          <ErrorTextBlock
            title={data?.pageNotFound404.mainTitle || ''}
            text={data?.pageNotFound404.mainText || ''}
            fontColor={data?.pageNotFound404.mainTextFgColor}
            backgroundImage={data?.pageNotFound404.mainTextBgImage}
            backgroundColor={data?.pageNotFound404.mainTextBgColor}
          />
        )}
      </Container>
      <DynamicComponents />
    </>
  );
};

const InternalErrorPage: React.FC = () => {
  const [t] = useTranslation();
  return (
    <Error
      text={t('common:serverError.text')}
      title={t('common:serverError.heading')}
    />
  );
};

const ErrorPage: NextPage<IErrorPageProps> = ({
  err,
  statusCode,
  getServerSideProps,
}) => {
  if (statusCode && statusCode !== 404) {
    if (!getServerSideProps && err) {
      // getInitialProps is not called in case of
      // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
      // err via _app.js so it can be captured
      Sentry.captureException(err);
      // Flushing is not required in this case as it only happens on the client
    }

    return <InternalErrorPage />;
  }

  return <NotFoundPage />;
};

function getCtxStatusCode(ctx: GetServerSidePropsContext): number {
  return ctx.req.statusCode || ctx.res.statusCode || 500;
}

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Page_404,
    async pageEventType(ctx) {
      return getCtxStatusCode(ctx) === 500 ? `error.500` : `error.404`;
    },
    namespaceRequired: [`common`, `search`, `home`],
  },
  [
    ServerSideUnit.createServerSideUnit(
      [apolloReqCtxUnitRef, gssUnitRef],
      async function get404PageUnit(ctx, { queryCtx, writeQuery }, gssPayload) {
        const statusCode = getCtxStatusCode(ctx);
        let travelType = mapGlobalSearchStateToFormState(gssPayload?.gss)
          .travelType;

        let meta = {
          title: `Error 500`,
        };

        if (statusCode !== 500) {
          try {
            const [{ data }, { data: searchControlData }] = await Promise.all([
              queryCtx<Get404MetaDataQuery, Get404MetaDataQueryVariables>({
                query: Get404MetaDataDocument,
              }),
              queryCtx<
                GetHomeSearchControlValuesQuery,
                GetHomeSearchControlValuesQueryVariables
              >({
                query: GetHomeSearchControlValuesDocument,
                variables: { travelType },
              }),
              queryCtx<
                Get404StaticComponentsQuery,
                Get404StaticComponentsQueryVariables
              >({ query: Get404StaticComponentsDocument }),
            ]);

            travelType = searchControlData?.home.searchControl?.travelType;

            if (travelType) {
              writeQuery<
                GetHomeSearchControlValuesQuery,
                GetHomeSearchControlValuesQueryVariables
              >({
                query: GetHomeSearchControlValuesDocument,
                variables: { travelType },
                data: searchControlData,
              });
            }

            meta = data.pageNotFound404.pageMetaData;
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Get404MetaData Error`, e);
          }
        }

        return {
          props: {
            meta,
            statusCode,
            getServerSideProps: true,
          },
        };
      }
    ),
  ] as any
);

export default ErrorPage;
