import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type Get404StaticComponentsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type Get404StaticComponentsQuery = {
  __typename?: 'Query';
  pageNotFound404: {
    __typename?: 'PageNotFound404Data';
    mainTitle: string;
    mainText: string;
    mainTextBgColor?: string | null;
    mainTextFgColor?: string | null;
    mainTextBgImage?: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  };
};

export const Get404StaticComponentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Get404StaticComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageNotFound404' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainTextBgColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainTextFgColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainTextBgImage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '1140' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '520' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '768' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '648' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGet404StaticComponentsQuery__
 *
 * To run a query within a React component, call `useGet404StaticComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet404StaticComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet404StaticComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGet404StaticComponentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    Get404StaticComponentsQuery,
    Get404StaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    Get404StaticComponentsQuery,
    Get404StaticComponentsQueryVariables
  >(Get404StaticComponentsDocument, options);
}
export function useGet404StaticComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Get404StaticComponentsQuery,
    Get404StaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    Get404StaticComponentsQuery,
    Get404StaticComponentsQueryVariables
  >(Get404StaticComponentsDocument, options);
}
export type Get404StaticComponentsQueryHookResult = ReturnType<
  typeof useGet404StaticComponentsQuery
>;
export type Get404StaticComponentsLazyQueryHookResult = ReturnType<
  typeof useGet404StaticComponentsLazyQuery
>;
export type Get404StaticComponentsQueryResult = Apollo.QueryResult<
  Get404StaticComponentsQuery,
  Get404StaticComponentsQueryVariables
>;
