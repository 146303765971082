import Head from 'next/head';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/graphql.types';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { GlobalStyle } from 'theme/globalStyles';

const TextWrapper = styled.div(
  sx2CssThemeFn({
    p: [4, 4, 0],
    display: 'flex',
    margin: '0 auto',
    maxWidth: '700px',
    flexDirection: 'column',
    justifyContent: 'center',
    height: ['calc(100vh - 61px)', 'calc(100vh - 74px)'],
    fontSize: [2, 4],
    h1: {
      mb: [3, 4],
      fontSize: [3, '30px'],
    },
  })
);

interface IErrorProps {
  text: string;
  title: string;
  headerLogo?: Image;
}

const Error: React.FC<IErrorProps> = ({ text, title }) => {
  return (
    <>
      <GlobalStyle />
      <Head>
        <title>{title}</title>
      </Head>
      <TextWrapper>
        <h1>{title}</h1>
        <div>{text}</div>
      </TextWrapper>
    </>
  );
};

export default Error;
